<template>
  <div>
    <div class="page">
      <div class="page__title">模組未啟用</div>
    </div>
    <div class="page__button">
      <!-- <SharedButton class="s-btn-bg-primary" @click="handleSubmit">{{ buttonText }}</SharedButton> -->
      <button type="button" class="s-btn s-liff-theme-btn-outline mt-2" @click="closeLiff">關閉</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import https from "@/apis/liff/v2/https";
import liff from "@line/liff";

export default {
  components: {
    // SharedButton,
  },
  data() {
    return {
      oaUrl: null,
    };
  },
  computed: {
    ...mapGetters({
      orgCode: "liffGeneral/orgCode",
    }),
    buttonText() {
      return this.$route.query.redirect_text || '重新載入'
    },
    redirectUrl() {
      return this.$route.query.redirect_uri || this.oaUrl
    },
  },
  mounted() {
    // this.fetchLiffGeneral()
  },
  methods: {
    async fetchLiffGeneral() {
      const response = await https.get(`${this.orgCode}/liff/general/info`)
      console.log('response', response)
      this.oaUrl = response.data.data.liff_oa_url
    },
    handleSubmit() {
      if (this.redirectUrl) {
        window.location.href = this.redirectUrl
      } else {
        // 只在 liff app 有用
        liff.closeWindow()
        window.close()
      }
    },
    closeLiff() {
      liff.closeWindow()
      window.close()
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
